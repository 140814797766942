<template>
  <div>
    <h1>Page not found</h1>

    <p>
      <router-link
        :to="{name: 'Home'}"
      >
        Back to home
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>

</style>
